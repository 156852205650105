import React from 'react';
import ReactDOM from 'react-dom';
import {compose} from 'redux';

import AppStateHOC from '../lib/app-state-hoc.jsx';
import GUI from '../containers/gui.jsx';
import TokenErrorPage from '../components/token-error-page/token-error-page.jsx';
import log from '../lib/log.js';

const onClickLogo = () => {
    window.location = 'https://www.amco.me';
};

const handleTelemetryModalCancel = () => {
    log('User canceled telemetry modal');
};

const handleTelemetryModalOptIn = () => {
    log('User opted into telemetry');
};

const handleTelemetryModalOptOut = () => {
    log('User opted out of telemetry');
};

const API_URL = process.env.API_URL;

/*
 * Render the GUI playground. This is a separate function because importing anything
 * that instantiates the VM causes unsupported browsers to crash
 * {object} appTarget - the DOM element to render to
 */
export default appTarget => {
    GUI.setAppElement(appTarget);

    // note that redux's 'compose' function is just being used as a general utility to make
    // the hierarchy of HOC constructor calls clearer here; it has nothing to do with redux's
    // ability to compose reducers.
    const WrappedGui = compose(
        AppStateHOC
    )(GUI);

    // TODO a hack for testing the backpack, allow backpack host to be set by url param
    const backpackHostMatches = window.location.href.match(/[?&]backpack_host=([^&]*)&?/);
    const backpackHost = backpackHostMatches ? backpackHostMatches[1] : null;

    const scratchDesktopMatches = window.location.href.match(/[?&]isScratchDesktop=([^&]+)/);
    let simulateScratchDesktop;
    if (scratchDesktopMatches) {
        try {
            // parse 'true' into `true`, 'false' into `false`, etc.
            simulateScratchDesktop = JSON.parse(scratchDesktopMatches[1]);
        } catch {
            // it's not JSON so just use the string
            // note that a typo like "falsy" will be treated as true
            simulateScratchDesktop = scratchDesktopMatches[1];
        }
    }

    if (process.env.NODE_ENV === 'production' && typeof window === 'object') {
        // Warn before navigating away
        window.onbeforeunload = () => true;
    }

    const urlParams = new URLSearchParams(window.location.search);
    let token = urlParams.get('t');

    if (token) {
        sessionStorage.setItem('token', token);
        window.location.replace('/');
    } else {
        token = sessionStorage.getItem('token');
    }

    const onVmInit = vm => {
        fetch(`${API_URL}/api/projects?token=${token}`)
            .then(response => response.arrayBuffer())
            .then(arrayBuffer => {
                vm.loadProject(arrayBuffer).then(() => {
                    vm.emit('EREBUS_LOADED_PROJECT');
                });
            });
    };

    const renderScratch = json => {
        ReactDOM.render(
            // important: this is checking whether `simulateScratchDesktop` is truthy, not just defined!
            simulateScratchDesktop ?
                <WrappedGui
                    canEditTitle
                    isScratchDesktop
                    showTelemetryModal
                    canSave={false}
                    onTelemetryModalCancel={handleTelemetryModalCancel}
                    onTelemetryModalOptIn={handleTelemetryModalOptIn}
                    onTelemetryModalOptOut={handleTelemetryModalOptOut}
                /> :
                <WrappedGui
                    showEditMenu={false}
                    canEditTitle={false}
                    backpackVisible={false}
                    showComingSoon={false}
                    canChangeTheme={false}
                    canChangeLanguage={false}
                    canManageFiles={false}
                    canShare={false}
                    backpackHost={backpackHost}
                    canSave={json.user.id === json.author.id}
                    userUsername={json.user.email}
                    userFirstName={json.user.first_name}
                    userLastName={json.user.last_name}
                    authorUsername={json.author.email}
                    onClickLogo={onClickLogo}
                    activityName={json.activity.name}
                    token={token}
                    onVmInit={onVmInit}
                />,
            appTarget);
    };

    const renderError = json => {
        ReactDOM.render(<TokenErrorPage />, appTarget);
        log(json);
    };

    fetch(`${API_URL}/api/tokens?token=${token}`)
        .then(response => response.json())
        .then(json => (json.error ? renderError(json) : renderScratch(json)))
        .catch(() => renderError());
};

import React from 'react';
import {FormattedMessage} from 'react-intl';

import styles from './token-error-page.css';

import banSvg from './ban.svg';
import logoSvg from './logo.svg';

const TokenErrorPage = () => (
    <div className={styles.container}>
        <div className={styles.wrapper}>
            <img
                className={styles.logo}
                src={logoSvg}
                alt="Amco"
            />

            <div className={styles.errorCode}>
                <FormattedMessage
                    defaultMessage="403"
                    description="Label indicating error code"
                    id="gui.error.forbiddenCode"
                />
            </div>

            <div className={styles.errorWrapper}>
                <img
                    className={styles.noSymbol}
                    src={banSvg}
                    alt="Forbidden"
                />

                <div className={styles.errorTitle}>
                    <FormattedMessage
                        defaultMessage="Acceso denegado"
                        description="Label indicating error title"
                        id="gui.error.forbiddenTitle"
                    />
                </div>

                <div className={styles.errorDescription}>
                    <FormattedMessage
                        defaultMessage="No tiene permisos para acceder a esta página."
                        description="Label indicating error description"
                        id="gui.error.forbiddenDescription"
                    />
                </div>
            </div>
        </div>
    </div>
);

export default TokenErrorPage;
